<!--
 * @Author: 月魂
 * @Date: 2021-11-28 15:15:15
 * @LastEditTime: 2022-11-14 16:49:40
 * @LastEditors: 月魂
 * @Description: 开屏页
 * @FilePath: \food-show\src\views\Index.vue
-->
<template>
  <div class="container" ref="box">
    <img src="../assets/poster-mobile.jpg" alt="" class="img mobile">
    <img src="../assets/poster-pc.jpg" alt="" class="img pc">
    <img src="../assets/poster-btn.png" alt="" class="img btn" @click="jump">
  </div>
</template>

<script>

export default {
  name: 'Index',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    jump() {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: #100726;
  .img {
    display: block;
    width: 100%;
  }
  .pc {
    display: none;
  }
  @media (min-width: 1000PX) {
    .mobile {
      display: none;
    }
    .pc {
      display: block;
    }
  }
  .btn {
    display: block;
    width: 30%;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    animation: myOpacity 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: pointer;
    @media (min-width: 1000PX) {
      width: 100px;
    }
    @keyframes myOpacity {
      0% {
        opacity: 1;
        // scale: 1;
      }
      50% {
        opacity: .7;
        // scale: .9;
      }
      75% {
        opacity: 1;
        // scale: 1;
      }
      100% {
        opacity: .7;
        // scale: .9;
      }
    }
  }
}
</style>
